import logo from './logo.png'

export const theme = {
  color: {
    primary: "#83888E",
    primaryHover: "#757a80",
  },
  logoImage: logo,
  backend: "https://news.unitedshippinggroup.de/",
  directory: "/",
  header: {
    customer: "",
    title: "UNITED SHIPPING GROUP",
    lineHeader: true,
  },
  info: [
    {
      text: 'Impressum',
      link: 'https://unitedshippinggroup.de/'
    },
    {
      text: 'Datenschutz',
      link: 'https://unitedshippinggroup.de/'
    }
  ],
  login: {
    type: "internal",
  }
};